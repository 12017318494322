import axios from 'axios'
import { createSimpleStore } from 'react-simple-reducer'
import toast from 'react-hot-toast'
import { createSelector } from 'reselect'
import { apiBaseUrl } from '../App'
import {
  CupomModel,
  DadosAlunoModel,
  MEIO_PAGAMENTO,
  IContentPassoAPasso,
  IContentComoFunciona,
  MatriculaModel,
} from '../models/MatriculaModel'
import { ESTADOS, ETAPAS } from './MatriculasConstants'

enum TIPOS_CURSO {
  LIVRE = 'LIVRE',
  CREDENCIADO_DETRAN = 'CREDENCIADO_DETRAN',
}

export enum CATEGORIA_CURSO {
  CAPACITACAO = 'Especialização',
  ATUALIZACAO = 'Atualização',
}

export interface CursoModel {
  id: number
  nome: string
  cargaHoraria: number
  limiteHorasDia: number
  duracaoMaximaDias: number
  necessitaValidacaoFacial: boolean
  diasParaEmissaoCertificado: number
  tipoCurso: TIPOS_CURSO
  urlSlug: string
  categorias: string
}

export type TMunicipiosDetran = {
  codigoMunicipio: number
  nome: string
}

type uf = keyof typeof ESTADOS
type TEstados = {
  [k in uf]: TMunicipiosDetran[]
}

export const MatriculaStore = createSimpleStore(
  {
    uf: '',
    curso: null as CursoModel | null,
    valorCurso: 0,
    etapa: ETAPAS.DADOS_ALUNO,
    codTicket: '' as string | undefined,
    categoriaCurso: '' as CATEGORIA_CURSO,

    loading: {
      matricula: false,
      validacaoAluno: false,
      cupom: false,
      campos: false,
    },

    passoAPasso: {
      titles: [],
      imgs: [],
      contents: [],
    } as IContentPassoAPasso,

    comoFunciona: {
      paraQuem: '',
      objetivo: '',
      tempoCurso: '',
      tutores: '',
    } as IContentComoFunciona,

    dadosAluno: {
      nome: '',
      cpf: '',
      renach: '',
      dataNascimento: '',
      dataPrimeiraHabilitacao: '',
      estado: '',
      municipio: '',
      email: '',
      telefone: '',
      cnh: '',
      categoriaCnh: '',
      codigoMunicipio: '',
    } as DadosAlunoModel,

    validacaoAluno: {
      loading: false,
      executouValidacao: false,
      valido: false,
      tipoErro: '',
      mensagemErro: '',
    },

    matricula: null as MatriculaModel | null,

    error: null as string | null,
    meioPagamento: null as MEIO_PAGAMENTO,

    estados: [] as any[],
    municipios: [] as any,

    campos: [] as string[],
  },
  {
    init(
      state,
      payload: {
        curso?: CursoModel
        uf: string
        valor: number
        campos: string[]
        categoriaCurso?: CATEGORIA_CURSO
      }
    ) {
      if (payload.uf) state.uf = payload.uf.toUpperCase()
      if (payload.curso) state.curso = payload.curso
      if (payload.campos) state.campos = payload.campos
      if (payload.curso && payload.uf) state.etapa = ETAPAS.DADOS_ALUNO
      if (payload.valor) state.valorCurso = payload.valor
      if (payload.categoriaCurso) state.categoriaCurso = payload.categoriaCurso
    },
    changeUf(state, uf: string) {
      state.uf = uf
    },
    updateDadosAluno(state, dadosAluno: Partial<DadosAlunoModel>) {
      const oldDadosAluno = state.dadosAluno || {}
      state.dadosAluno = { ...oldDadosAluno, ...dadosAluno }
    },
    proximaEtapa(state) {
      state.error = null
      state.etapa += 1
    },
    etapaAnterior(state) {
      state.error = null
      state.etapa -= 1
    },
    validaAlunoStarted(state) {
      state.validacaoAluno.loading = true
    },
    validaAlunoSuccess(
      state,
      status: { success: boolean; errorType: string; errorMessage: string; codTicket?: string }
    ) {
      state.validacaoAluno.loading = false
      state.validacaoAluno.executouValidacao = true
      state.validacaoAluno.valido = status.success
      state.validacaoAluno.tipoErro = status.errorType
      state.validacaoAluno.mensagemErro = status.errorMessage
      state.error = null
      state.etapa += 1
      state.codTicket = status?.codTicket
    },
    validaAlunoError(state, error: string | null) {
      state.validacaoAluno.loading = false
      state.error = error === '' ? null : error
    },
    matricularStarted(state) {
      state.loading.matricula = true
    },
    matricularSuccess(state, matricula: MatriculaModel) {
      state.loading.matricula = false
      state.matricula = matricula
      state.etapa = ETAPAS.PAGAMENTO
    },
    matricularError(state) {
      state.loading.matricula = false
    },
    changeError(state, error: string | null) {
      state.error = error === '' ? null : error
    },
    applyCupomStarted(state) {
      state.loading.cupom = true
    },
    applyCupomSuccess(state, matricula: MatriculaModel) {
      state.matricula = matricula
      state.loading.cupom = false
    },
    applyCupomError(state) {
      state.loading.cupom = false
    },
    pagamentoSuccess(state, meioPagamento?: MEIO_PAGAMENTO) {
      if (!meioPagamento) return
      state.meioPagamento = meioPagamento
      state.etapa = ETAPAS.SUCESSO
    },
    getDadosEstados(state, estados) {
      state.estados = estados
    },
    getDadosMunicipio(state, municipio) {
      state.municipios = municipio
    },
    setLoadingCampos(state, payload: boolean) {
      state.loading.campos = payload
    },
  },
  {
    thunks: {
      matricular() {
        return async (dispatch, getState) => {
          try {
            const { dadosAluno, uf, curso, valorCurso, codTicket } = getState()
            dispatch(MatriculaStore.actions.matricularStarted())

            const matricula = await axios
              .post<MatriculaModel>('/matriculas', {
                uf,
                cursoId: curso?.id,
                valor: valorCurso,
                ...dadosAluno,
                codTicket,
              })
              .then((x) => x?.data)

            dispatch(MatriculaStore.actions.matricularSuccess(matricula))
          } catch (error: any) {
            toast(error.response?.data?.message || 'Falha ao efetuar a matrícula!', {})
            dispatch(MatriculaStore.actions.matricularError())
          }
        }
      },
      validaAluno(form) {
        return async (dispatch, getState) => {
          dispatch(MatriculaStore.actions.validaAlunoStarted())
          const toastId = toast.loading('Validando!')
          try {
            const uf = getState().uf
            const cursoId = getState().curso?.id
            const body = { uf, cursoId, ...form }
            const responseValidar = await axios
              .post('/matriculas/validar', body)
              .then((x) => x.data)
            if (!responseValidar.success) {
              dispatch(
                MatriculaStore.actions.validaAlunoError(
                  responseValidar?.errorMessage || 'Falha ao validar matrícula'
                )
              )
              toast.error(responseValidar?.errorMessage || 'Falha ao validar matrícula', {
                id: toastId,
              })
              return
            }
            dispatch(MatriculaStore.actions.validaAlunoSuccess(responseValidar))
            toast.success('Validação efetuada com sucesso!', { id: toastId })
          } catch (error: any) {
            dispatch(
              MatriculaStore.actions.validaAlunoError(
                error.response?.data?.message || 'Falha ao validar matrícula'
              )
            )
            toast.error(error.response?.data?.message || 'Falha ao validar matrícula', {
              id: toastId,
            })
          }
        }
      },
      applyCupom(cupomCodigo) {
        return async (dispatch, getState) => {
          const { matricula } = getState()
          try {
            dispatch(MatriculaStore.actions.applyCupomStarted())
            const matriculaWithNovoCupom = await axios
              .post(`${apiBaseUrl}/matriculas/aplicar-cupom`, {
                matriculaId: matricula?.id,
                cupomCodigo,
              })
              .then((x) => x?.data)
            toast('Desconto aplicado!')
            dispatch(MatriculaStore.actions.applyCupomSuccess(matriculaWithNovoCupom))
          } catch (error: any) {
            const message = error?.response?.data?.message ?? 'Error ao buscar cupom!'
            toast(message)
            dispatch(MatriculaStore.actions.applyCupomError())
          }
        }
      },
      getEstados() {
        return async (dispatch) => {
          try {
            const estados = await axios
              .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`, {
                params: {
                  orderBy: 'nome',
                },
              })
              .then((rs) => rs.data)
            dispatch(MatriculaStore.actions.getDadosEstados(estados))
          } catch (error: any) {
            const message = error?.response?.data?.message ?? 'Erro ao buscar os municípios!'
            toast(message)
          }
        }
      },
      getMunicipios({ estados }) {
        return async (dispatch) => {
          try {
            const municipios = await axios
              .get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estados}/municipios`
              )
              .then((rs) => rs.data)
            dispatch(MatriculaStore.actions.getDadosMunicipio(municipios))
          } catch (error: any) {
            const message = error?.response?.data?.message ?? 'Erro ao buscar os municípios!'
            toast(message)
          }
        }
      },
      getMunicipiosDetran({ estados }) {
        return async (dispatch) => {
          try {
            const municipios: TEstados = await axios
              .get(`${apiBaseUrl}/matriculas/get-municipios-detran`, {
                params: { uf: estados },
              })
              .then((rs) => rs.data)
            dispatch(MatriculaStore.actions.getDadosMunicipio(municipios[estados]))
          } catch (error: any) {
            const message = error?.response?.data?.message ?? 'Erro ao buscar os municípios!'
            toast(message)
          }
        }
      },
      getValorCurso(uf: string, urlSlug: string, categoriaCurso: CATEGORIA_CURSO) {
        return async (dispatch, getState) => {
          try {
            dispatch(MatriculaStore.actions.setLoadingCampos(true))
            axios
              .get('/cursos/valor', { params: { uf, curso: urlSlug } })
              .then((res) => {
                const { data } = res
                if (!data.valor) {
                  return toast.error('Erro ao buscar valor do curso')
                  return (window.location.href = 'https://newdriver.com.br/cursos-estados')
                }
                dispatch(MatriculaStore.actions.setLoadingCampos(false))
                dispatch(
                  MatriculaStore.actions.init({
                    curso: data.curso,
                    uf: uf,
                    valor: data.valor,
                    campos: data.campos,
                    categoriaCurso,
                  })
                )
              })
              .catch((error) => {
                dispatch(MatriculaStore.actions.setLoadingCampos(false))
                return toast.error('Erro ao buscar valor do curso')
                // window.location.href = 'https://newdriver.com.br/cursos-estados'
              })
          } catch (error) {}
        }
      },
    },
  }
)

type IState = ReturnType<typeof MatriculaStore.useState>

export const selectEstado = createSelector(
  (s: IState) => s.curso,
  (s: IState) => s.uf,
  (curso, uf) => {
    if (!uf || !ESTADOS[uf]) return { nome: '', uf: '', link: '' }
    const { nome, hifenizado } = ESTADOS[uf]
    return { nome, uf, link: `https://newdriver.com.br/${hifenizado}` }
    // return CURSOS_ESTADOS.find((c) => c.curso === curso?.curso)?.estados.find((e) => e.uf === uf)
  }
)
